import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmp
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import { getClusterFromEndpoint, hasEndpointCapClusterId } from "../../services/device-helpers";
import ClusterConstants from "../../services/cluster-constants";
import { ROTATE_DEVICE_TYPE } from "../../services/utils";
// types
import type { ReadonlyDeep } from "type-fest";
import type { Endpoint, EpDevice, EndpointId } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"0006">;
	showFull: boolean;
	multipleEpHandler: boolean;
};

const D0006 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const endpoints: Array<ReadonlyDeep<Endpoint>> = props.multipleEpHandler
		? props.epDevice.eps.filter((endpoint) => (hasEndpointCapClusterId(endpoint, props.deviceType.cap, props.deviceType.clusterId)))
		: [props.epDevice.ep];

	const value = endpoints.map((endpoint) => {
		const cluster = getClusterFromEndpoint(endpoint, props.deviceType.cap, props.deviceType.clusterId);
		return Boolean(props.deviceType.formatValue(cluster));
	}).some((value) => (value));

	const timeoutIdRef = useRef<number | undefined>(undefined);
	const [inProgress, setInProgress] = useState<"on" | "off" | undefined>(undefined);
	const [toggle, setToggle] = useDynamicUpdateState(value);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	const sendToggleCmd = (isInputChecked: boolean, epId: EndpointId) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: isInputChecked ? ClusterConstants.D0006.CmdIds.TurnOnDevice : ClusterConstants.D0006.CmdIds.TurnOffDevice,
			frameControl: (endpoints.length > 1) ? "11" : undefined,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleToggle = (isInputChecked: boolean) => {
		setInProgress(isInputChecked ? "on" : "off");
		setToggle(isInputChecked);
		for (const endpoint of endpoints) {
			sendToggleCmd(isInputChecked, endpoint.endpoint);
		}
		timeoutIdRef.current = window.setTimeout(() => {
			setInProgress(undefined);
		}, 3000);
	};

	const rotateToggle = ROTATE_DEVICE_TYPE.includes(props.epDevice.getRocIdData("type"));
	const onLabelText = t("clusters.D0006.on", { context: props.epDevice.stringOverrideKey });
	const offLabelText = t("clusters.D0006.off", { context: props.epDevice.stringOverrideKey });

	const isGroup = props.epDevice.getRocIdData("is_group");
	const controls = isGroup
		? (
			<>
				<LoadingButton
					className="button-0006"
					variant={props.showFull ? "contained" : "text"}
					loading={inProgress === "off"}
					disabled={Boolean(inProgress)}
					onClick={() => (handleToggle(false))}
				>
					{offLabelText}
				</LoadingButton>
				<LoadingButton
					className="button-0006"
					variant={props.showFull ? "contained" : "text"}
					loading={inProgress === "on"}
					disabled={Boolean(inProgress)}
					onClick={() => (handleToggle(true))}
					sx={{ ml: props.showFull ? 1 : undefined }}
				>
					{onLabelText}
				</LoadingButton>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		) : (
			<>
				<CustomSwitch
					className="switch-0006"
					checked={toggle}
					onChange={handleToggle}
					vertical={rotateToggle}
				/>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);

	if (props.showFull) {
		return (
			<ListItem sx={rotateToggle ? { height: "58px" } : {}}>
				<ListItemText primary={isGroup ? t("clusters.D0006.setGroupTo") : toggle ? onLabelText : offLabelText} />
				<ListItemContainer>
					{controls}
				</ListItemContainer>
			</ListItem>
		);
	}

	return controls;
};

D0006.defaultProps = {
	showFull: false,
	multipleEpHandler: false,
};

D0006.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		formatValue: PropTypes.func.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
	multipleEpHandler: PropTypes.bool,
};

export default D0006;
