import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import DimSlider from "../dim-slider";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import { decimal2Hex } from "../../services/utils";
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceType<"0008">;
	showFull: boolean;
};

const D0008 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	const [dimLevel, setDimLevel] = useDynamicUpdateState(props.deviceType.getValue(cluster));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleChangeCommitted = (newDimLevel: number) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0008.CmdIds.MoveToLevelWithOnOff,
			value: `${decimal2Hex(Math.round(newDimLevel * 255 / 100), 2)},ffff`,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText
						primary={t("clusters.D0008.title", { context: props.epDevice.stringOverrideKey })}
						secondary={`${dimLevel}${props.deviceType.getUnit()}`}
					/>
					<ListItemContainer style={{ width: "calc(50% - 10px)", marginRight: "10px" }}>
						<DimSlider
							value={dimLevel}
							onChange={(newDimLevel: number) => (setDimLevel(newDimLevel))}
							onChangeCommitted={handleChangeCommitted}
						/>
					</ListItemContainer>
				</ListItem>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

D0008.defaultProps = {
	showFull: false,
};

D0008.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
		getValue: PropTypes.func.isRequired,
		getUnit: PropTypes.func.isRequired,
	}).isRequired,
	showFull: PropTypes.bool,
};

export default D0008;
